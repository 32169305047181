.chart {
  display: block;
  min-height: 10rem;

  text {
    font-family: inherit;
  }
}

.chart-sm {
  height: 2.5rem;
  //outline: 1px solid red;
}

.chart-square {
  height: 5.75rem;
  //outline: 1px solid red;
}

.chart-placeholder {
  background-image: linear-gradient(135deg, $border-color 25%, transparent 25%, transparent 50%, $border-color 50%, $border-color 75%, transparent 75%, transparent 100%);
  background-size: 14.14px 14.14px;
}

.sparkline {
  position: relative;
  width: 4rem;
  height: 2.5rem;
  line-height: 1;
}

.sparkline-square {
  width: 2.5rem;
}

.sparkline-wide {
  width: 6rem;
}

.sparkline-label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: px2rem(11px);

  .icon {
    width: 1rem;
    height: 1rem;
  }
}


// stylelint-disable declaration-no-important
.apexcharts-tooltip {
  line-height: 1;
  color: #fff;
  background: $dark !important;
  border: 0 !important;
  border-radius: 3px !important;
}

.apexcharts-tooltip-title {
  margin: 0 !important;
  background: transparent !important;
  border: 0 !important;
}

.apexcharts-tooltip-series-group {
  padding: 0 8px !important;
}
